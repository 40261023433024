<template>
  <div class="members">
    <online-members
      :poker-session="pokerSession"
      @remove-user="removeUser"
    />
    <div class="add-member-btn">
      <el-button
        icon="el-icon-plus"
        circle
        class="btn"
        @click="showUserWindow"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembersColumn',
  components: {
    OnlineMembers: () => import('./OnlineMembers.vue'),
  },
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      userName: '',
    };
  },
  methods: {
    showUserWindow() {
      this.$emit('open-search');
    },
    removeUser(user) {
      this.$emit('remove-user', user);
    },
  },
};
</script>

<style scoped>
  .members {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .add-member-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
</style>
